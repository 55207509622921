//INFO: 프로젝트 게시판
<script>
	export default {
		name: '',
		data() {
			return {
				example: ''
		}
	},
		setup() {},
		created() {},
		mounted() {},
		unmounted() {},
		methods: {}
	}
</script>

<template>
	<div id="content-wrapper" class="d-flex flex-column">
		<div id="content">
			<!-- 제목 입력 -->
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <h4>프로젝트 게시판</h4>
      </nav>

			<!-- 프로젝트 게시판 children(리스트, 등록, 수정)-->
			<router-view></router-view>
		</div>
	</div>
</template>
